<template>
  <div style="font-weight: 200;">
    <div class="header">
      <img class="bg" src="/img/otherBg.png" alt />
      <div class="content">
        <div>
          <div class="title">公司介绍</div>
          <div class="desc">
            用科技创造未来 /
            <br />循环和再生，让地球更年轻
          </div>
        </div>
      </div>
    </div>

    <div class="first">
      <div>
        <div class="title">我们的愿景</div>
        <div class="desc">精准溯源、靶向分类、长效机制、模范体系</div>
      </div>
    </div>

    <div class="second">
      <div
        class="box"
      >宁波搭把手生态数字科技有限公司是一家基于城市生活垃圾智慧感知设备研发，提供城市固废全流程数字化、智慧化治理方案的技术型公司。是为了顺利建设和实施2021年启动的宁波世行贷款二期而成立的SPV（特殊目的）公司。注册资本1.5亿人民币，利用世界银行贷款8千万美金。由宁波供销再生资源科技有限公司出资44%、宁波市政公用投资有限公司出资32%及市区所辖8个区域的出资代表出资24%。经营范围包括固废管理；城市全品类垃圾精准分类项目开发运营管理、塑料垃圾分类减排；软件开发、销售；资源再生利用技术研发；数据处理；供应链管理服务；公共事业管理服务；城市生活垃圾经营性服务等。</div>
      <div class="img">
        <img src="/img/VCG41N1196161943.png" alt />
      </div>
    </div>

    <!-- <el-row class="third">
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <div class="box">
          <img src="/img/about-footer1.png" alt />
          <div class="content">
            <div class="title">我们的办公区</div>
            <div class="desc">我们的办公区分为接待区、开放式办公区、各领导办公室、茶水间、会议室等功能区域，公司办公环境优美，设施完善，室内整洁明亮。</div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <div class="box">
          <img src="/img/IMG_508E28D50C68-1.png" alt />
          <div class="content">
            <div class="title">我们的展区</div>
            <div class="desc">展区陈列了可回收智能投递箱（柜）、智能单体投瓶机、智能过磅机等产品，还有综合数据墙展示可视化用户数据，欢迎来参观！</div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <div class="box">
          <img src="/img/about-footer3.png" alt />
          <div class="content">
            <div class="title">我们的园区</div>
            <div class="desc">园区是我们研发、组装、测试产品的基地。</div>
          </div>
        </div>
      </el-col>
    </el-row>-->
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
@import "./header.scss";
.first {
  background: url(/img/about-bg.png) left bottom/100% auto no-repeat;
  & > div {
    width: 80%;
    margin: 0 auto;
    max-width: 120rem;
    padding: 15rem 0 20rem;
    .title {
      font-size: 3rem;
      line-height: 3.5rem;
      letter-spacing: 1px;
      color: #288eff;
      margin-bottom: 3rem;
    }
    .desc {
      font-size: 5rem;
      line-height: 8rem;
      letter-spacing: 1px;
      color: #252525;
    }
  }
}

.second {
  width: 100%;
  height: 42vw;
  position: relative;
  .img {
    width: 80%;
    max-width: 1400px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
      display: block;
    }
  }
  .box {
    position: absolute;
    right: 0;
    top: 0;
    width: 80%;
    padding: 10rem;
    box-sizing: border-box;
    max-width: 96rem;
    //    margin-bottom: 33rem;
    background-color: #0670d8;
    font-size: 2.2rem;
    line-height: 5rem;
    letter-spacing: 1px;
    color: #ffffff;
    margin-top: -5vw;
    z-index: 2;
  }
}

.third {
  padding: 0 20px;
  .box {
    width: 80%;
    margin: 7rem auto;
    position: relative;
    img {
      width: 100%;
    }
    .content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 70%;
      background: linear-gradient(
        180deg,
        rgba(0, 9, 34, 0.0001) -2.08%,
        rgba(0, 9, 34, 0.510883) 35.69%,
        #000922 100%
      );
      box-sizing: border-box;
      padding: 30% 5.8rem;
      .title {
        font-size: 4rem;
        line-height: 4.7rem;
        letter-spacing: 1px;
        color: #ffffff;
      }
      .desc {
        font-size: 2rem;
        line-height: 4rem;
        letter-spacing: 1px;
        color: #ffffff;
        margin-top: 3.6rem;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .second {
    height: 45vw;
  }
}

@media screen and (max-width: 1200px) {
  .second {
    height: 50vw;
  }
}

@media screen and (max-width: 992px) {
  .second {
    height: 53vw;
  }
}

@media screen and (max-width: 768px) {
  .second {
    height: auto;
    .box {
      width: 100vw;
      min-width: 100vw;
      position: relative;
      margin-top: 0;
    }
    .img {
      position: relative;
      top: -20px;
      width: 90vw;
      height: 90vw;
      overflow: hidden;
      z-index: 3;
      img {
        min-width: 100%;
        min-width: 100%;
        width: auto;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
